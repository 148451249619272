import React from "react";
import LpDemo from "../../../page-components/LpDemo";

export default function DemoPageWebPayments() {
  return (
    <LpDemo
      leadType="lead-gen"
      formSubHeader="Adapt and grow your business with an end-to-end techonology and payments."
      formId="b94421a4-8d31-494c-9528-5d157cc229f9"
      useShortForm
      thankYouPath="/lp/website-payments/thank-you"
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
      imageName="websitePayments-pp-hero.png"
      withLineOfBusinessOption
      toggleVisibilityMap={[
        {
          changeOnKey: "industry",
          changeOnValue: "Other",
          togglesItem: "business_type",
        },
      ]}
    />
  );
}
